<template>
  <div class="wrap">
    <Title pageTitle="TRY ON" pageDiscrption="試着サービス" />
    <div class="container">
      <h1 class="heading-1">採寸通りがベストなサイズとは限りません。<br />着用して初めて分かる事があります</h1>
      <div class="discription">
        <p>採寸した通りの寸法で作れば身体に合ったTシャツは作れます。しかし身体に合わせただけのものがお客様にとってベストのTシャツになるとは限りません。</p>
        <p>
          体型とは違うスタイルのTシャツを好む方もいますし、こだわりを追求したりコンプレックスを解消するためにオリジナルのサイズを希望する方など一人ひとり仕様が異なります。そのため本当のオーダーTシャツの製作とは体型に合わせた所から始まると当店は考えています。
        </p>
        <p>
          Tシャツに限らずオンラインでのオーダーメイド商品は採寸データを元に算出したサイズで納品するという流れが一般的だと思いますが、商品が届くまで仕上がりに不安を感じたり、実際に着用したら期待していたものと違かったりと中々満足するものにならなかったというような声は良く聞きます。
        </p>
        <p>そのような中で、当店ではお客様にベストなTシャツを提供するために、試着サービスを最も重要な工程と位置付けています。</p>
      </div>

      <div class="container-narrow">
        <div class="flow">
          <div class="flow__category">受注から納品までの流れ</div>
          <div class="flow__item">
            <div class="flow__item--img">
              <img src="../assets/img/tryon-pattern.jpg" alt="" />
            </div>
            <div class="flow__item--desc">
              <h3 class="heading-3">注文内容を元にサンプルを製作</h3>
              <p>採寸データを元にお客様専用の型紙を作成し、注文時にお選びいただいた仕様で試着用のサンプルTシャツを製作します。</p>
            </div>
            <div class="flow__item--note">サンプルTシャツはサイズ確認が目的のため、簡易縫製で製作いたします</div>
          </div>
          <div class="flow__arrow"><span class="material-icons">keyboard_arrow_down</span></div>

          <div class="flow__item">
            <div class="flow__item--img">
              <img src="../assets/img/tryon-sample.jpg" alt="" />
            </div>
            <div class="flow__item--desc">
              <h3 class="heading-3">サンプルを発送・お届け</h3>
              <p>サンプルTシャツ完成後、専用パックにてサンプルTシャツをお届けします。到着しましたら内容のご確認をお願いします。</p>
            </div>
            <div class="flow__item--note">サンプルTシャツは返却の必要はありません</div>
          </div>
          <div class="flow__arrow"><span class="material-icons">keyboard_arrow_down</span></div>

          <div class="flow__item">
            <div class="flow__item--img">
              <img src="../assets/img/tryon-fit.jpg" alt="" />
            </div>
            <div class="flow__item--desc">
              <h3 class="heading-3">試着・サイズ確認</h3>
              <p>実際にサンプルTシャツをご試着いただき、同封の案内を参考にきつい所やゆるい所がないか各箇所のサイズをご確認ください。</p>
            </div>
            <div class="flow__item--note">脱着時は変形を避けるため、なるべく伸ばさずに着用してください</div>
          </div>
          <div class="flow__arrow"><span class="material-icons">keyboard_arrow_down</span></div>

          <div class="flow__item">
            <div class="flow__item--img" style="border: 1px solid var(--color-medium)">
              <img src="../assets/img/tryon-form.jpg" alt="" />
            </div>
            <div class="flow__item--desc">
              <h3 class="heading-3">チェックフォームを配信</h3>
              <p>サンプルTシャツ発送後に修正用のチェックフォームを配信します。届きましたら各項目に修正内容を記入して送信ください。</p>
            </div>
            <div class="flow__item--note">日常生活を考慮して、多少ゆとりのあるサイズを設定ください</div>
          </div>
          <div class="flow__arrow"><span class="material-icons">keyboard_arrow_down</span></div>

          <div class="flow__item">
            <div class="flow__item--img">
              <img src="../assets/img/tryon-deliver.jpg" alt="" />
            </div>
            <div class="flow__item--desc">
              <h3 class="heading-3">商品を製作・発送</h3>
              <p>チェックフォームからの返信内容を反映し、商品用のTシャツを製作します。納品はチェックフォームの返信後、１週間程度のお時間をいただいております。</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Title from '../components/Title.vue'

export default {
  components: { Title },
}
</script>

<style lang="scss" scoped>
.wrap {
  background-image: url('../assets/img/tryon-bg.jpg');
  background-size: 110%;
  background-position: center top;
  background-repeat: no-repeat;
  width: 100%;

  @media screen and (max-width: 767px) {
    background-size: 160%;
  }
}
.discription {
  column-count: 2;
  column-gap: 5rem;

  @media screen and (max-width: 767px) {
    column-count: 1;
  }
}
.flow {
  margin-top: 10rem;
  text-align: center;
  &__category {
    background-color: #000;
    display: inline-block;
    font-size: 13px;
    font-weight: 300;
    letter-spacing: 2px;
    padding: 5px 25px 7px;
    border-radius: 100px;
    margin-bottom: 8rem;
  }

  &__item {
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-template-rows: 2fr;
    grid-column-gap: 3rem;
    grid-row-gap: 1rem;

    @media screen and (max-width: 599px) {
      grid-template-columns: 1fr;
    }

    &--desc {
      & .heading-3 {
        text-align: left;
        margin-bottom: 20px;
        border-bottom: 1px solid var(--color-dark);
        padding-bottom: 15px;

        @media screen and (max-width: 599px) {
          margin-top: 10px;
        }
      }
    }

    &--img img {
      width: 100%;
    }

    &--note {
      grid-column: 1 / -1;
      font-size: 13px;
      background-color: #111;
      padding: 10px 20px;
      border: 1px solid var(--color-dark);
      border-radius: 100px;

      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &__arrow {
    margin: 3rem 0;
  }
}
</style>
